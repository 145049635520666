export enum VerificationProcessEnum {
  AadharFinger = "aadhar-finger",
  AadharIris = "aadhar-iris",
  AadharFace = "aadhar-face",
  Facial = "facial",
  Iris = "iris",
  Fingerprint = "fingerprint",
  GPS = "gps",
  QRCode = "qr-code",
  Face = "face"
}
