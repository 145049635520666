import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faFingerprint, faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import Select from "react-select"
import { StatsForCenterFailure, StatsForRegisteredDevice, UsersCard } from '../../component/Card/Statistics.card';
import { ClientService } from '../../component/services/client.service';
import { CentersStats, InvigilatorStats, UserStats } from '../../component/Stats/Stats';
import FilterTable from '../../component/table/filter.table';
import TablePaginationComponent from '../../component/table/TablePagination';

const FilterIndex = () => {

    const [selectValue, setSelectValue] = useState<any>();
    const [shifts, setShifts] = useState<any>();

    const [zones, setZones] = useState<any>();
    const [districts, setDistricts] = useState<any>();
    const [centers, setCenters] = useState<any>();
    const [states, setStates] = useState<any>();


    const [selectedZones, setSelectedZones] = useState<any>();
    const [selectedDistricts, setSelectedDistricts] = useState<any>();
    const [selectedCenters, setSelectedCenters] = useState<any>();
    const [selectedStates, setSelectedStates] = useState<any>();
    const [selectedVerification, setSelectedVerification] = useState<any>();
    const [tableData, setTableData] = useState<any>();
    const [userData, setUserData] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [totalItems, setTotalItems] = useState<number>(0);

    const [verificationEnums, setVerificationEnums] = useState<any>();

    const getVerificationEnums = async () => {
        await ClientService.getVerificationEnums().then(res => {
            if (res.status === 200) {
                console.log(res.data, "enums")
                setVerificationEnums(Object.values(res.data.enums).map((data: any) => {
                    return {
                        label: data,
                        value: data
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName,
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getDropdownData =async() => {
        await ClientService.getDropdownData(selectValue).then((res) => {
            if(res.status === 200) {
                console.log(res.data, "dropfown")
            }
        })
    }

    const getAllZones = async () => {
        await ClientService.getUniqueZone(selectValue).then(res => {
            if (res.status === 200) {
                setZones(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCenter = async () => {
        await ClientService.getUniqueCenter(selectValue).then(res => {
            if (res.status === 200) {
                setCenters(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueState = async () => {
        await ClientService.getUniqueState(selectValue).then(res => {
            if (res.status === 200) {
                setStates(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCity = async () => {
        await ClientService.getUniqueCity(selectValue).then(res => {
            if (res.status === 200) {
                setDistricts(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const handleGetExamData = async () => {
        setLoading(true)
        if (selectValue) {
            getAllZones();
            getUniqueCenter();
            getUniqueCity();
            getUniqueState();
        }
        setLoading(false)
    }

    const getTableData = async (pageNumber: number = 1, pageSize: number = 10) => {
        setLoading(true)
        const payload = {
            zone: selectedZones && selectedZones.length > 0 ? selectedZones.map((zone: any) => zone.value) : [],
            district: selectedDistricts && selectedDistricts.length > 0 ? selectedDistricts.map((dist: any) => dist.value) : [],
            centers: selectedCenters && selectedCenters.length > 0 ? selectedCenters.map((center: any) => center.value) : [],
            state: selectedStates && selectedStates.length > 0 ? selectedStates.map((state: any) => state.value) : [],
            verificationMethods: selectedVerification && selectedVerification.length > 0 ? selectedVerification.map((state: any) => state.value) : [],
        }
        await ClientService.getUsers(selectValue, pageSize, pageNumber, payload)
            .then(res => {
                if (res.status === 200) {
                    setTableData(res.data);
                    setTotalItems(res.data.totalUsers || 0);
                }
            }).catch(err => {
                toast.error(err.response.data);
            })
            .finally(() => {
                setLoading(false)
            })
    }



    const getUserData = async (pageNumber: number = 1, pageSize: number = 10) => {
        setLoading(true)
        const payload = {
            zone: selectedZones && selectedZones.length > 0 ? selectedZones.map((zone: any) => zone.value) : [],
            city: selectedDistricts && selectedDistricts.length > 0 ? selectedDistricts.map((dist: any) => dist.value) : [],
            center: selectedCenters && selectedCenters.length > 0 ? selectedCenters.map((center: any) => center.value) : [],
            state: selectedStates && selectedStates.length > 0 ? selectedStates.map((state: any) => state.value) : [],
            methods: selectedVerification && selectedVerification.length > 0 ? selectedVerification.map((state: any) => state.value) : [],
        }
        await ClientService.getUsersData(selectValue, pageSize, pageNumber, payload)
            .then(res => {
                if (res.status === 200) {
                    setUserData(res.data.students);
                    setTotalItems(res.data.total || 0);
                }
            }).catch(err => {
                toast.error(err.response.data);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function handleClick() {
        if (page === 1) {
            getTableData(1, itemsPerPage);
            getUserData(1,itemsPerPage)
        } else {
            setPage(1);
        }
    }

    useEffect(() => {
        if (selectedZones?.length > 0 || selectedDistricts?.length > 0 || selectedCenters?.length > 0 || selectedStates?.length > 0 || selectedVerification?.length > 0 ) {
            getTableData(page, itemsPerPage);
            getUserData(page, itemsPerPage);
        }
    }, [page, itemsPerPage]);

    useEffect(() => {
        if(selectValue) {
            getDropdownData()
        }
    }, [selectValue])


    useEffect(() => {
        getAllShift();
        getVerificationEnums();
    }, [])


    return (
        <>
            <div className="d-flex justify-content-start align-items-center pt-4 mb-4">
                <Select
                    options={shifts}
                    onChange={(e: any) => setSelectValue(e.value)}
                    placeholder="Select shift"
                />
                <Button className="ms-2" onClick={handleGetExamData}>Get Data</Button>
            </div>
            <div>
                <Card className="shadow-sm mt-3 p-2 mb-3 bg-white rounded">
                    <Card.Body>
                        <Row>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label className="text-muted fw-bold">
                                        Zones
                                    </Form.Label>
                                    <Select
                                        options={zones}
                                        onChange={setSelectedZones}
                                        value={selectedZones}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isMulti
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label className="text-muted fw-bold">
                                        Centers
                                    </Form.Label>
                                    <Select
                                        options={centers}
                                        onChange={setSelectedCenters}
                                        value={selectedCenters}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isMulti
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label className="text-muted fw-bold">
                                        Districts
                                    </Form.Label>
                                    <Select
                                        options={districts}
                                        onChange={setSelectedDistricts}
                                        value={selectedDistricts}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isMulti
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label className="text-muted fw-bold">
                                        States
                                    </Form.Label>
                                    <Select
                                        options={states}
                                        onChange={setSelectedStates}
                                        value={selectedStates}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isMulti
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className = "mt-3">
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label className="text-muted fw-bold">
                                        Verification Type
                                    </Form.Label>
                                    <Select
                                        options={verificationEnums}
                                        onChange={setSelectedVerification}
                                        value={selectedVerification}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isMulti
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={() => { handleClick() }} className="d-flex mt-3" variant="warning">
                                    <FontAwesomeIcon icon={faGear} className="me-2 mt-1" />
                                    Get Records
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {tableData &&
                    <div className="d-flex justify-content-center align-items-center mb-3">
                        <Row>
                            <Col md={4}>
                                <UserStats statsData={tableData && tableData.chart3} />
                            </Col>
                            <Col md={4}>
                                <CentersStats statsData={tableData && tableData.chart2} />
                            </Col>
                            <Col md={4}>
                                <InvigilatorStats statsData={tableData && tableData.chart1} />
                            </Col>
                        </Row>
                    </div>
                }
                {tableData && <Row>
                    <h4 className="d-flex text-secondary">Center Data</h4>
                    <Col>
                        <Card className="shadow-sm p-3 mb-3 bg-white rounded">
                            <Card.Body>
                                <FilterTable tableData={userData} currentPage={page} itemsPerPage={itemsPerPage} shiftId={selectValue} loading = {loading} />
                                <TablePaginationComponent
                                    itemsCount={totalItems}
                                    itemsPerPage={itemsPerPage}
                                    setItemsPerPage={setItemsPerPage}
                                    currentPage={page}
                                    setCurrentPage={setPage}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>}
            </div>
        </>
    )
}

export default FilterIndex