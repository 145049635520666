import React, { useEffect, useState } from 'react'
import Select from "react-select"
import { ClientService } from '../../component/services/client.service';
import toast from 'react-hot-toast';
import { Card, Col, Row, Table } from 'react-bootstrap';
import TablePaginationComponent from '../../component/table/TablePagination';
import { resolveModuleName } from 'typescript';
import { FaFileImage, FaFilePdf } from 'react-icons/fa';
import PreviewModal from '../../component/modals/Preview.modal';

export default function CSRReport() {

    const [shifts, setShifts] = useState<any>();
    const [selectValue, setSelectValue] = useState<any>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalCount, setTotolCount] = useState<number>(0);
    const [csrData, setCsrData] = useState<any>();

    const [showPreviewModal, setShowPreviewModal] = useState<any>(undefined)

    const getFileTypeFromUrl = (url: any) => {
        const fileExtensions: any = {
            '.pdf': 'pdf',
            '.jpg': 'image',
            '.jpeg': 'image',
            '.png': 'image',
            '.gif': 'image'
        };

        const extension = url.slice(url.lastIndexOf('.')).toLowerCase();

        return fileExtensions[extension] || 'unknown';
    };

    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName,
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getAllCSRReport = async () => {
        await ClientService.getAllCSRReport(selectValue, pageSize, pageNumber).then((res) => {
            if (res.status === 200) {
                console.log(res.data, "csr data")
                setCsrData(res.data.data.CSRReports);
                setTotolCount(res.data.data.totalCsrReports)
            }
        })
    }

    useEffect(() => {
        if (selectValue) {
            getAllCSRReport();
        }
    }, [selectValue, pageNumber])

    useEffect(() => {
        getAllShift();
    }, [])

    return (
        <>
            <div className="d-flex justify-content-start pt-4 mb-4 gap-3">
                <Select
                    options={shifts}
                    onChange={(e: any) => setSelectValue(e.value)}
                    placeholder="Select shift"
                />
            </div>
            <Row>
                <Col>
                    <h4 className="d-flex text-secondary">CSR Report</h4>
                    <Card className="shadow-sm mt-3 p-2 mb-3 bg-white rounded">
                        <Table striped hover responsive>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Invigilator Username</th>
                                    <th>Center Name</th>
                                    <th>Exam Name</th>
                                    <th>Report</th>
                                </tr>
                            </thead>
                            <tbody>
                                {csrData?.map((data: any, index: any) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{data?.invigilatorId?.userName}</td>
                                            <td>{data?.invigilatorId?.centerId[0] || "--"}</td>
                                            <td>{data?.examId?.name}</td>
                                            <td>
                                                {data?.CSRReport?.map((fileUrl: any, index: number) =>
                                                    getFileTypeFromUrl(fileUrl) === "image" ? (
                                                        <FaFileImage key={index} onClick={() => setShowPreviewModal(fileUrl)} />
                                                    ) : (
                                                        <a href={fileUrl} download={true} className = "text-decoration-none">
                                                        <FaFilePdf key={index} />
                                                        </a>
                                                    )
                                                )}
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TablePaginationComponent
                        currentPage={pageNumber}
                        itemsCount={totalCount}
                        itemsPerPage={pageSize}
                        setItemsPerPage={(e: any) => setPageSize(e)}
                        setCurrentPage={setPageNumber}
                    />
                </Col>
            </Row>

            <PreviewModal
                show={showPreviewModal}
                handleClose={() => setShowPreviewModal(undefined)}

            />
        </>
    )
}
