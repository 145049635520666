import React from 'react';
import { Badge, Card, Image, Spinner, Table } from 'react-bootstrap';

interface ICsrReportTable {
  data: any[];
  pageNumber: number;
  pageSize: number;
  loading?: boolean;
}

const CsrReportTable = ({ data, pageNumber, pageSize, loading = false }: ICsrReportTable) => {
  console.log(data, "header");

  function getHeaders() {
    if (!data || !data.length) return [];

    let keys = new Set<string>();

    Object.entries(data[0]).forEach(([key, value]) => {
      if (typeof value === 'string' || typeof value === 'number') {
        keys.add(key);
      } else if (key === 'verificationDetails' && Array.isArray(value)) {
        value.forEach((verification: any, idx: number) => {
          if (verification.method) {
            keys.add(`Verification (${verification.method})`);
          }
        });
      }
    });

    return Array.from(keys);
  }

  console.log(getHeaders(), "getHeaders");

  return (
    <Card className="shadow p-3 mb-3 mt-5 bg-white rounded">
      <Card.Body>
        <Table striped bordered hover size="sm" responsive>
          <thead>
            <tr>
              {loading ? (
                <></>
              ) : (
                getHeaders().map((header, index) => (
                  <th key={index}>{header}</th>
                ))
              )}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={getHeaders().length}>
                  <span>Loading data... <Spinner animation="border" size="sm" /></span>
                </td>
              </tr>
            ) : data && data.length > 0 ? (
              data.map((item, index) => (
                <tr key={item?._id || index}>
                  {getHeaders().map((header, idx) => {
                    if (header === "Live Photo" || header === "Photo") {
                      const photoUrl = item[header];
                      return (
                        <td key={idx}>
                          <span className="fs-12 cursor-pointer">
                            <Image src={photoUrl} height="40" width="40" />
                          </span>
                        </td>
                      );
                    } else if (header.startsWith("Verification (") && Array.isArray(item.verificationDetails)) {
                      const method = header.replace("Verification (", "").replace(")", "");
                      const verification = item.verificationDetails.find((v: any) => v.method === method);
                      return (
                        <td key={idx} className="fs-italic fs-12">
                          {verification ? verification.value : "N/A"}
                        </td>
                      );
                    } else {
                      return (
                        <td key={idx} className="fs-italic fs-12">
                          {item[header] || "N/A"}
                        </td>
                      );
                    }
                  })}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={getHeaders().length} className="text-center">
                  <span className="fs-12 fw-bold">No item found</span>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default CsrReportTable;
