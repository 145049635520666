import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Pagination, Row, Spinner, Table } from 'react-bootstrap'
import toast from 'react-hot-toast';
import Select from "react-select"
import { UsersCard } from '../../component/Card/Statistics.card';
import { ClientService } from '../../component/services/client.service';
import CsrReportTable from '../../component/table/CsrReportTable';
import downloadjs from 'downloadjs';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import UserModal from '../../component/modals/User.modal';
import { FaRegEye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export default function CenterCount() {

    const navigate = useNavigate();


    const [selectValue, setSelectValue] = useState<any>();
    const [shifts, setShifts] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [centerData, setCenterData] = useState<any>([])

    const [zones, setZones] = useState<any>();
    const [districts, setDistricts] = useState<any>();
    const [centers, setCenters] = useState<any>();
    const [states, setStates] = useState<any>();


    const [selectedZones, setSelectedZones] = useState<any>();
    const [selectedDistricts, setSelectedDistricts] = useState<any>();
    const [selectedCenters, setSelectedCenters] = useState<any>();
    const [selectedStates, setSelectedStates] = useState<any>();



    const getAllZones = async () => {
        await ClientService.getUniqueZone(selectValue).then(res => {
            if (res.status === 200) {
                setZones(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCenter = async () => {
        await ClientService.getUniqueCenter(selectValue).then(res => {
            if (res.status === 200) {
                setCenters(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueState = async () => {
        await ClientService.getUniqueState(selectValue).then(res => {
            if (res.status === 200) {
                setStates(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCity = async () => {
        await ClientService.getUniqueCity(selectValue).then(res => {
            if (res.status === 200) {
                setDistricts(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName,
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getCenterStats = async () => {
        setLoading(true)
        await ClientService.getCenterStats(selectValue, selectedCenters?.value, selectedZones?.value, selectedStates?.value, selectedDistricts?.value)
            .then((res) => {
                if (res.status === 200) {
                    setCenterData(res.data.centerStats);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data);
            });
        setLoading(false)
    };


    useEffect(() => {
        if (selectValue) {
            getAllZones();
            getUniqueCenter();
            getUniqueCity();
            getUniqueState();
        }
    }, [selectValue])

    useEffect(() => {
        getAllShift();
    }, [])

    useEffect(() => {
        if (selectValue) {
            getCenterStats();
        }
    }, [selectedCenters, selectedDistricts, selectedStates, selectedZones, selectValue])

    return (
        <>
            <div className="d-flex justify-content-start pt-4 mb-4 gap-3">
                <Select
                    options={shifts}
                    onChange={(e: any) => setSelectValue(e.value)}
                    placeholder="Select shift"
                />
                {/* <Button onClick={getCenterStats}>Fetch Data</Button> */}
            </div>

            <Card className="shadow-sm mt-3 p-2 mb-3 bg-white rounded">
                <Card.Body>
                    <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    States
                                </Form.Label>
                                <Select
                                    options={states}
                                    onChange={(e: any) => setSelectedStates(e)}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Zones
                                </Form.Label>
                                <Select
                                    options={zones}
                                    onChange={(e: any) => setSelectedZones(e)}
                                    value={selectedZones}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    City
                                </Form.Label>
                                <Select
                                    options={districts}
                                    onChange={(e: any) => setSelectedDistricts(e)}
                                    value={selectedDistricts}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Centers
                                </Form.Label>
                                <Select
                                    options={centers}
                                    onChange={(e: any) => setSelectedCenters(e)}
                                    value={selectedCenters}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {loading && <Spinner animation="border" className="mt-4" />}
            <Row>
                <h4 className="d-flex text-secondary">Center Stats</h4>
                <Col>
                    <Card className="shadow-sm mt-3 p-2 mb-3 bg-white rounded">
                        <Table striped hover responsive>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Center Name</th>
                                    <th>State</th>
                                    <th>City</th>
                                    <th>Zone</th>
                                    <th>Enrolled Candidates</th>
                                    <th>Verified Candidates</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {centerData?.map((data: any, index: any) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{data?.centerPreference}</td>
                                            <td>{data?.state}</td>
                                            <td>{data?.city}</td>
                                            <td>{data?.zone}</td>
                                            <td>{data?.totalUsers}</td>
                                            <td>{data?.verifiedUsers}</td>
                                            <td><FaRegEye className='text-primary' onClick={() => navigate("/center-stats/" + selectValue + '/' + data?.centerPreference)} /> </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Card>
                </Col>
            </Row>
        </>
    )
}