import { faBullseye, faFilter, faSearch, faSquarePollVertical } from "@fortawesome/free-solid-svg-icons";
import CsrIndex from "../../View/Csr/Csr.index";
import FilterIndex from "../../View/Filter/Filter.index";
import IRouter from "../Interface/IRouter";
import SearchIndex from "../../View/Search/Search.index";
import CenterCount from "../../View/CenterCount/CenterCount.index";
import CenterStatsDetails from "../../View/CenterStatsDetails/CenterStatsDetails";
import CSRReport from "../../View/CSRReport/CSRReport";
import BypassDetails from "../../View/Bypass/Bypass";




const mainRoutes: IRouter[] = [
    {
        path: "center-report",
        navbarShow: true,
        element: CsrIndex,
        name: "Center Report",
        icon: faSquarePollVertical
    },
    {
        path: "center-data",
        navbarShow: true,
        element: FilterIndex,
        name: "Center Data",
        icon: faFilter
    },
    {
        path: "search",
        navbarShow: true,
        element: SearchIndex,
        name: "Search Student",
        icon: faSearch
    },
    {
        path: "center-stats",
        navbarShow: true,
        element: CenterCount,
        name: "Center Stats",
        icon: faSearch
    },
    {
        path: "csr-report",
        navbarShow: true,
        element: CSRReport,
        name: "CSR Report",
        icon: faSearch
    },
    {
        path: "bypass",
        navbarShow: true,
        element: BypassDetails,
        name: "Bypass Candidates",
        icon: faSearch
    },
    {
        path: "center-stats/:shiftId/:centerId",
        navbarShow: false,
        element: CenterStatsDetails,
        name: "Center Stats",
        icon: faSearch
    },
]


export default mainRoutes;