import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Card, Col, Row } from 'react-bootstrap';

ChartJS.register(ArcElement, Tooltip, Legend);


interface IStats {
    statsData: any
}


export function UserStats({ statsData }: IStats) {

    const data = {
        labels: ['Verified Candidates', 'Not Verified Candidates'],
        datasets: [
            {
                label: '# of counts',
                data: [statsData?.verifiedUsers, statsData?.notVerifiedUsers],
                backgroundColor: ["#D0B022", "#4BABF4"],
            },
        ],
    };

    return (
        <Card className="xcn-stats-card-dough" >
            <Card.Body >
                <Row className="pb-2" style={{ borderBottom: "2px solid #c1c1c1" }} >
                    <Col md={6} style={{ borderRight: "2px solid #c1c1c1" }}>
                        <h4 className="fw-bold" style={{ color: "#c1c1c1" }} >Candidates</h4>
                        <span className="fw-bold text-muted" style={{ fontSize: "23px", marginRight: "6rem" }}>{statsData?.totalUsers}</span>
                    </Col>
                    <Col md={6}>
                        <Doughnut data={data} />
                    </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-between p-2">
                    <span className="text-muted" style={{ fontSize: 10, fontWeight: "500" }}>Total Candidates: {statsData?.totalUsers}</span>
                    <span className="text-muted" style={{ fontSize: 10, fontWeight: "500" }}>Verified Candidates: {statsData?.verifiedUsers}</span>
                    <span className="text-muted" style={{ fontSize: 10, fontWeight: "500" }}>Not-verified Candidates: {statsData?.notVerifiedUsers}</span>

                </div>
            </Card.Body>
        </Card>
    )
}

export function CentersStats({ statsData }: IStats) {

    const data = {
        labels: ['Active Centers', 'In-Active Centers', 'Not LoggedIn Centers'],
        datasets: [
            {
                label: '# of counts',
                data: [statsData?.activeCenters, statsData?.inActiveCenters, statsData?.notLoggedInCenters],
                backgroundColor: ["#D0B022", "#4BABF4", "#AD4BF4"],
                // borderColor: [],
                // borderWidth: 1,
            },
        ],
    };

    return (
        <Card className="xcn-stats-card-dough">
            <Card.Body>
                <Row className="pb-2" style={{ borderBottom: "2px solid #c1c1c1" }} >
                    <Col md={6} style={{ borderRight: "2px solid #c1c1c1" }}>
                        <h4 className="fw-bold" style={{ color: "#c1c1c1" }} >Centers</h4>
                        <span className="fw-bold text-muted" style={{ fontSize: "23px", marginRight: "4rem" }}>{statsData?.totalCenters}</span>
                    </Col>
                    <Col md={6}>
                        <Doughnut data={data} />
                    </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-between p-2">
                    <span className="text-muted" style={{ fontSize: 10, fontWeight: "500" }}>Active Centers: {statsData?.activeCenters}</span>
                    <span className="text-muted" style={{ fontSize: 10, fontWeight: "500" }}>In-active Centers: {statsData?.inActiveCenters}</span>
                    <span className="text-muted" style={{ fontSize: 10, fontWeight: "500" }}>Not-loggedIn Centers: {statsData?.notLoggedInCenters}</span>
                </div>
            </Card.Body>
        </Card>
    )
}

export function InvigilatorStats({ statsData }: IStats) {

    const data = {
        labels: ['Active Invigilators', 'In-Active Invigilators', 'Not Logged-In Invigilators'],
        datasets: [
            {
                label: '# of counts',
                data: [statsData?.onlineInvigilators, statsData?.offlineInvigilators, statsData?.notLoggedInInvigilators],
                backgroundColor: ["#D0B022", "#4BABF4", "#AD4BF4"],
                // borderColor: [],
                // borderWidth: 1,
            },
        ],
    };

    return (
        <Card className="xcn-stats-card-dough">
            <Card.Body>
                <Row className="pb-2" style={{ borderBottom: "2px solid #c1c1c1" }} >
                    <Col md={6} style={{ borderRight: "2px solid #c1c1c1" }}>
                        <h4 className="fw-bold" style={{ color: "#c1c1c1" }} >Invigilators</h4>
                        <span className="fw-bold text-muted" style={{ fontSize: "23px", marginRight: "7rem" }}>{statsData?.totalInvigilators}</span>
                    </Col>
                    <Col md={6}>
                        <Doughnut data={data} />
                    </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-between p-2">
                    <span className="text-muted" style={{ fontSize: 10, fontWeight: "500" }}>Total Inv.: {statsData?.totalInvigilators}</span>
                    <span className="text-muted" style={{ fontSize: 10, fontWeight: "500" }}>Active Inv.: {statsData?.onlineInvigilators}</span>
                    <span className="text-muted" style={{ fontSize: 10, fontWeight: "500" }}>In-active Inv.: {statsData?.offlineInvigilators}</span>
                    <span className="text-muted" style={{ fontSize: 10, fontWeight: "500" }}>Not-loggedIn Inv.: {statsData?.notLoggedInInvigilators}</span>
                </div>
            </Card.Body>
        </Card>
    )
}

