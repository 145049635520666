import React from 'react'
import { Button, Image, Modal } from 'react-bootstrap';

interface IPreviewModal {
    show: any,
    handleClose: any
}

export default function PreviewModal(props: IPreviewModal) {
    return (
        <Modal
            show={props.show ? true : false}
            onHide={props.handleClose}
            size="lg"
            animation={true}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="d-flex align-items-center justify-content-center mb-1">
                        <div style={{ width: 500, height: 500 }}>
                            <Image src={props.show} width={"100%"} height={"100%"} style = {{objectFit: 'contain'}} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal >
    )
}
