import { faGear, faArrowRightArrowLeft, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Navbar } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import IRouter from '../Interface/IRouter';
import './Topbar.css';

interface ITopBar {
  menuData: IRouter[],
  panelName: string,
  baseUrl?: string
}

export default function TopBar({ menuData, panelName, baseUrl }: ITopBar) {

  let navigate = useNavigate();
  const location = useLocation();

  const showAllowedMenu = menuData.filter((routes) => routes.navbarShow === true)

  const handleLogout = () => {
    sessionStorage.removeItem("authKey");
    navigate("/login")
  };

  return (
    <>
      <Navbar className="pl-3 d-flex shadow-sm" bg='dark' variant='dark'>
        <div className="d-flex align-items-center me-auto">
          <Navbar.Brand className="ms-2 fw-bold" style={{ color: "white" }}>Nixauth-Client</Navbar.Brand>
          <div className="d-flex">
            {showAllowedMenu.map((data, index) => (
              <div key={index}
                className={"xrg-nav-link"}
                onClick={() => navigate(data.path)}
              >
                <div className='xrg-nav-item mt-3'>
                  <div className="xrg-nav-close d-flex align-items-center mb-3">
                    <span
                      className={"nav-btn me-2 fw-normal" + (data.path === location.pathname.split("/")[1] ? " xrg-nav-selected text-white fw-bold" : "")}
                      style={{ cursor: "pointer" }}
                    >
                      {data.name}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex justify-content-end ms-auto">
          <Button className="me-3" size="sm" onClick={handleLogout}>Logout</Button>
        </div>
      </Navbar>
    </>
  );
}
