import React, { useEffect, useState } from 'react'
import Select from "react-select"
import { ClientService } from '../../component/services/client.service';
import toast from 'react-hot-toast';
import { Card, Col, Image, Row, Table } from 'react-bootstrap';
import TablePaginationComponent from '../../component/table/TablePagination';
import { resolveModuleName } from 'typescript';
import { FaFileImage, FaFilePdf } from 'react-icons/fa';
import PreviewModal from '../../component/modals/Preview.modal';
import moment from 'moment';

export default function BypassDetails() {

    const [shifts, setShifts] = useState<any>();
    const [selectValue, setSelectValue] = useState<any>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalCount, setTotolCount] = useState<number>(0);

    const [showPreviewModal, setShowPreviewModal] = useState<any>(undefined)
    const [bypassData, setBypassData] = useState<any[]>([]);


    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName,
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getAllBypassData = async () => {
        await ClientService.getAllBypassData(selectValue, pageSize, pageNumber)
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data);  // Log the data
                    setBypassData(res.data.bypassVerifications);
                    setTotolCount(res.data.totalBypassVerifications);
                }
            })
            .catch((err) => {
                toast.error(err.response?.data || "An error occurred");
            });
    };


    useEffect(() => {
        if (selectValue) {
            getAllBypassData();
        }
    }, [selectValue, pageNumber, pageSize]);


    useEffect(() => {
        getAllShift();
    }, [])

    return (
        <>
            <div className="d-flex justify-content-start pt-4 mb-4 gap-3">
                <Select
                    options={shifts}
                    onChange={(e: any) => setSelectValue(e.value)}
                    placeholder="Select shift"
                />
            </div>
            <Row>
                <Col>
                    <h4 className="d-flex text-secondary">Bypass Candidates</h4>
                    <Card className="shadow-sm mt-3 p-2 mb-3 bg-white rounded">
                        <Table striped hover responsive>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Name</th>
                                    <th>Registration No.</th>
                                    <th>Bypass Type</th>
                                    <th>Bypass Sub-type</th>
                                    <th>Bypass Reason</th>
                                    <th>Bypass Image</th>
                                    <th>Bypass On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bypassData && bypassData.length > 0 ? (
                                    bypassData.map((data: any, index: number) => {
                                        console.log(data, "bypass")
                                        return (
                                            <tr key={data._id || index}>
                                                <td>{index + 1}</td>
                                                <td>{data?.studentId?.name || "N/A"}</td>
                                                <td>{data?.studentId?.regNo || "N/A"}</td>
                                                <td>{data?.verificationDetails && data?.verificationDetails[0]?.bypassType || "--"}</td>
                                                <td>{data?.verificationDetails && data?.verificationDetails[0]?.bypassSubType || "--"}</td>
                                                <td>{data?.verificationDetails && data?.verificationDetails[0]?.bypassReason || "--"}</td>
                                                <td>
                                                    {data?.verificationDetails && data?.verificationDetails[0]?.bypassImage ? (
                                                        <div style={{ width: 50, height: 50 }}>
                                                            <Image
                                                                src={data?.verificationDetails[0]?.bypassImage}
                                                                width="100%"
                                                                height="100%"
                                                                style={{ borderRadius: '50%' }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <span>No Image</span>
                                                    )}
                                                </td>
                                                <td>{data?.verificationDetails && moment(data?.verificationDetails[0]?.success_time).format("DD/MM/YYYY, HH:MM A") || "--"}</td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={8} className="text-center">No data available</td>
                                    </tr>
                                )}
                            </tbody>

                        </Table>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TablePaginationComponent
                        currentPage={pageNumber}
                        itemsCount={totalCount}
                        itemsPerPage={pageSize}
                        setItemsPerPage={(e: any) => setPageSize(e)}
                        setCurrentPage={setPageNumber}
                    />
                </Col>
            </Row>

            <PreviewModal
                show={showPreviewModal}
                handleClose={() => setShowPreviewModal(undefined)}

            />
        </>
    )
}
