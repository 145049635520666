import React from "react";
import { Tooltip } from "react-bootstrap";

import { BsGraphUp, BsGraphDown } from "react-icons/bs";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import "./card.css";

interface IChartForCenterAuth {
  centerId: string;
  authData: any;
}
interface IChartForRegisteredDevice {
  centerId: string;
  registeredDevices: number;
}

interface IChartForRegisteredUsers {
  centerId: string;
  registeredUsers: number;
}

interface IChartForCenterFailure {
  centerId: string;
  authDone: number;
}

interface Istatisticscard {
  reactIcon: any;
  header: string;
  footer?: any;
  statsValue: any;
  change?: {
    value: string;
    isNegative?: boolean;
  };
}

const authData = [
  {
    centerId: "xyz",
    authDone: 34,
  },
  {
    centerId: "abc",
    authDone: 44,
  },
  {
    centerId: "def",
    authDone: 56,
  },
  {
    centerId: "ghi",
    authDone: 24,
  },
  {
    centerId: "jkl",
    authDone: 64,
  },
];

const registeredDeviceData = [
  {
    centerId: "xyz",
    registeredDevices: 34,
  },
  {
    centerId: "hgf",
    registeredDevices: 44,
  },
  {
    centerId: "def",
    registeredDevices: 56,
  },
  {
    centerId: "ghi",
    registeredDevices: 24,
  },
  {
    centerId: "jkl",
    registeredDevices: 64,
  },
];

const registeredUserData = [
  {
    centerId: "xyz",
    registeredUsers: 34,
  },
  {
    centerId: "hgf",
    registeredUsers: 44,
  },
  {
    centerId: "def",
    registeredUsers: 56,
  },
  {
    centerId: "ghi",
    registeredUsers: 24,
  },
  {
    centerId: "jkl",
    registeredUsers: 64,
  },
];

const failureData = [
  {
    centerId: "xyz",
    failures: 34,
  },
  {
    centerId: "abc",
    failures: 44,
  },
  {
    centerId: "def",
    failures: 24,
  },
  {
    centerId: "ghi",
    failures: 54,
  },
  {
    centerId: "jkl",
    failures: 64,
  },
  //
];

export function StatisticsCard(props: Istatisticscard) {
  return (
    <>
      <div className="xcn-stats-card d-flex flex-column">
        <div className="d-flex justify-content-start">
          <div className="ms-3 mt-3 me-3">
            <div className="fw-bold text-secondary xcn-text-12">
              {props.header}
            </div>
            <div className="xcn-stats-card-value">{props.statsValue}</div>
          </div>
          <div className="xcn-stats-icon d-flex justify-content-end m-3 mt-4">
            {props.reactIcon}
          </div>
        </div>
        {props.change && (
          <div className="xcn-card-change ms-3">
            <span
              className={
                props.change.isNegative ? "text-danger" : "text-success"
              }
            >
              {props.change.isNegative ? "-" : "+"} {props.change.value}
              <span className="ms-2">
                {props.change.isNegative ? <BsGraphDown /> : <BsGraphUp />}
              </span>
            </span>
          </div>
        )}

        {props.footer && (
          <div className="xcn-stats-card-footer text-muted mt-auto px-3">
            {props.footer}
          </div>
        )}
      </div>
    </>
  );
}

export function CSRReportCard(props: Istatisticscard) {
  return (
    <>
      <div className="xcn-stats-card d-flex flex-column">
        <div className="d-flex justify-content-start">
          <div className="ms-3 mt-3 me-3">
            <div className="fw-bold text-secondary xcn-text-12">
              {props.header}
            </div>
            <div className="xcn-stats-card-value">{props.statsValue}</div>
          </div>
          <div className="xcn-stats-icon d-flex justify-content-end m-3 mt-4">
            {props.reactIcon}
          </div>
        </div>
        {props.change && (
          <div className="xcn-card-change ms-3">
            <span
              className={
                props.change.isNegative ? "text-danger" : "text-success"
              }
            >
              {props.change.isNegative ? "-" : "+"} {props.change.value}
              <span className="ms-2">
                {props.change.isNegative ? <BsGraphDown /> : <BsGraphUp />}
              </span>
            </span>
          </div>
        )}

        {props.footer && (
          <div className="xcn-stats-card-footer text-muted mt-auto px-3">
            {props.footer}
          </div>
        )}
      </div>
    </>
  );
}

export function LiveExamCard(props: Istatisticscard) {
  return (
    <>
      <div className="xcn-stats-card d-flex flex-column">
        <div className="d-flex justify-content-start">
          <div className="ms-3 mt-3 me-3">
            <div className="fw-bold text-secondary xcn-text-12">
              {props.header}
            </div>
            <div className="xcn-stats-card-value">{props.statsValue}</div>
          </div>
          <div className="xcn-stats-icon d-flex justify-content-end m-3 mt-4">
            {props.reactIcon}
          </div>
        </div>
        {props.change && (
          <div className="xcn-card-change ms-3">
            <span
              className={
                props.change.isNegative ? "text-danger" : "text-success"
              }
            >
              {props.change.isNegative ? "-" : "+"} {props.change.value}
              <span className="ms-2">
                {props.change.isNegative ? <BsGraphDown /> : <BsGraphUp />}
              </span>
            </span>
          </div>
        )}

        {props.footer && (
          <div className="xcn-stats-card-footer text-muted mt-auto px-3">
            {props.footer}
          </div>
        )}
      </div>
    </>
  );
}

export function UsersCard(props: Istatisticscard) {
  return (
    <>
      <div className="xcn-stats-card d-flex flex-column m-1">
        <div className="d-flex justify-content-start">
          <div className="ms-3 mt-3 me-3">
            <div className="fw-bold text-secondary xcn-text-12">
              {props.header}
            </div>
            <div className="xcn-stats-card-value">{props.statsValue}</div>
          </div>
          <div className="xcn-stats-icon d-flex justify-content-end m-3 mt-4">
            {props.reactIcon}
          </div>
        </div>
      </div>
    </>
  );
}

export function StatsForCenterAuthCard(props: IChartForCenterAuth) {
  return (
    <>
      <div className=" d-flex flex-row statsForCenterAuth">
        <ResponsiveContainer>
          <BarChart
            width={500}
            height={300}
            data={authData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="centerId" />
            <YAxis />
            <Tooltip />
            <Legend />
            {/* <Bar dataKey="pv" fill="#8884d8" /> */}
            <Bar dataKey="authDone" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

export function StatsForCenterFailure() {
  return (
    <>
      <div className=" d-flex flex-row statsForCenterAuth">
        <ResponsiveContainer>
          <BarChart
            width={500}
            height={300}
            data={failureData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="centerId" />
            <YAxis />
            <Tooltip />
            <Legend />
            {/* <Bar dataKey="pv" fill="#8884d8" /> */}
            <Bar dataKey="failures" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

export function StatsForRegisteredDevice(props: any) {
  return (
    <>
      <div className=" d-flex flex-row statsForCenterAuth">
        <ResponsiveContainer>
          <BarChart
            width={500}
            height={300}
            data={props.data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="centerId" />
            <YAxis />
            <Tooltip />
            <Legend />
            {/* <Bar dataKey="pv" fill="#8884d8" /> */}
            <Bar dataKey="registeredDevices" fill="#FFA500" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

export function StatsForRegisteredUsers(props: IChartForRegisteredUsers) {
  return (
    <>
      <div className=" d-flex flex-row statsForCenterAuth">
        <ResponsiveContainer>
          <BarChart
            width={500}
            height={300}
            data={registeredUserData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="centerId" />
            <YAxis />
            <Tooltip />
            <Legend />
            {/* <Bar dataKey="pv" fill="#8884d8" /> */}
            <Bar dataKey="registeredUsers" fill="#ffb6c1" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}
